import propTypes from 'prop-types';
import React from 'react';


const CategoryTitle = ({ title, metaDescription }) => {
  return (
    <div className="bg-white md:w-4/6 pt-6 pb-12 rounded-md">
      <h1 className="text-black text-3xl md:text-4xl sm:text-5xl md:text-5xl font-bold">
        <span className="font-lg">{title}</span>
      </h1>
      <h3 className="text-gray-800 text-lg font-normal mb-6 italic">{metaDescription}</h3>
    </div>
  );
};

CategoryTitle.propTypes = {
  title: propTypes.string.isRequired,
  metaDescription: propTypes.string.isRequired,
};

export default CategoryTitle;
